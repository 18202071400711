// i18next-extract-mark-ns-start products-vectis-workflow

import { VECTIS_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductVectisWorkflowPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const image = getImage(data.image.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							The value added by 3D CFD analysis is derived not only from the accuracy of the simulations, but also from the efficiency of
							the simulation workflows. Realis 3D CFD solutions put engineers in control and help them accelerate the complete simulation
							process and drive down the costs of the analysis. We enable you to :
						</p>
						<ul>
							<li>Build fully automated workflows covering the complete simulation from geometry preprocessing to the analysis
								of results with Python® and R-Desk scripting</li>
							<li>Create parametrised models and simulations, easily generating and tracking simulations of multiple
								configurations with a single source parameter table</li>
							<li>Easily analyse and compare outcomes of parametric simulations in a single simple and powerful post-processing environment</li>
							<li>Powerful User-Programmable Routines (UPR) interface allowing easy access to core solver data</li>
							<li>Construct seamless 1D/3D CFD integrations with Realis WAVE or other (GT-Power®) third party 1D CFD tools</li>
						</ul>
					</Trans>
					{image && (
						<p>
							<GatsbyImage image={image} alt="VECTIS workflow" />
						</p>
					)}
				</div>
				<Aside>
					<Aside.Widget
						title="Capabilities and applications"
						className="widget__sibling__pages">
						<AssetList>
							{VECTIS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductVectisWorkflowPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-vectis-workflow", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/products/vectis/Realis-Simulation_VECTIS.jpg" }) {
		...imageBreaker
	}
	image: file(relativePath: { eq: "content/products/vectis/Realis-Simulation_VECTIS_Workflow.png" }) {
		...imageBreaker
	}
}
`;

